import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Image, FlatList, useWindowDimensions, KeyboardAvoidingView, ScrollView, Platform, Alert } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, ClubAttendanceContext, OrganisationContext, UserContext } from "../hooks/Contexts";
// import { Image } from 'expo-image';
import { VibrateOnce } from "../functions/vibration";
import * as WebBrowser from "expo-web-browser";
import { API, Storage, graphqlOperation } from "aws-amplify";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { Feather } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import RegisterTabNavigator from "../navigation/registerTabs";
import { BoldText } from "../constants/styledText";
import SearchBar from "../components/SearchBarNew";
import TimelineIcon from "../../assets/icons/Timeline";
import PhotoListItem from "../components/PhotoListItem";
import { prettyDate } from "../functions/prettyDate";
import PhotoListItemRegister from "../components/PhotoListItemRegister";
import CloseButton from "../components/CloseButton";
import { ColorSpace } from "react-native-reanimated";
import PhotoListItemRegisterSeearchSuggestion from "../components/PhotoListItemRegisterSearchSuggestion";
import PhotoListItemRegisterSearch from "../components/PhotoListItemRegisterSearch";
import { useNavigation } from "@react-navigation/native";
import ActionButton from "../components/ActionButton";


import { clubAttendanceForThisClubQueryAndContextUpdate } from "../functions/ClubAttendanceForThisClubQueryAndContextUpdate";

export const clubAttendanceByOpportunityID = /* GraphQL */ `
  query ClubAttendanceByOpportunityID(
    $opportunityID: ID!
    $recordedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubAttendanceByOpportunityID(opportunityID: $opportunityID, recordedDate: $recordedDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendanceMark
        recordedDate
        userID
        User {
          id
          surname
          firstName
          preferredFirstName
          preferredSurname
          aaa
          title
          type
          photoURL
          dateOfBirth
          email
          gender
          isActive
          pushToken
          createdAt
          updatedAt
          Enrolments {
            items {
              groupingData1
              groupingData2
              groupingData3
            }
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        opportunityID
        Opportunity {
          name
          term
        }
        recordedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;




export const createClubAttendance = /* GraphQL */ `
  mutation CreateClubAttendance($input: CreateClubAttendanceInput!, $condition: ModelClubAttendanceConditionInput) {
    createClubAttendance(input: $input, condition: $condition) {
      id
      attendanceMark
      recordedDate
      userID
      opportunityID
      recordedByID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RecordedBy {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClubAttendance = /* GraphQL */ `
  mutation UpdateClubAttendance($input: UpdateClubAttendanceInput!, $condition: ModelClubAttendanceConditionInput) {
    updateClubAttendance(input: $input, condition: $condition) {
      id
      attendanceMark
      recordedDate
      userID
      opportunityID
      recordedByID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RecordedBy {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

const ActivitiesPreviousRegister = ({ route }) => {
  const { width } = useWindowDimensions();
  const { opportunityID, date } = route.params;
  const colorScheme = useColorScheme();
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);
  const allUsersFilteredByType = allUserDetailsFromAllUsersContext.filter((user) => user.type === "CHILD");
  const [filteredWithoutAdded, setFilteredWithoutAdded] = useState([]);
  const [filteredData, setFilteredData] = useState(allUsersFilteredByType);
  const [masterData, setMasterData] = useState(allUsersFilteredByType);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const { allClubAttendanceForThisClub, updateClubAttendanceContext } = useContext(ClubAttendanceContext);

  const clubAttendanceFromContext = allClubAttendanceForThisClub;
  const goodLookingDate = prettyDate(date);
  const targetDate = date;
  const [isSearchAndSuggestionSectionVisible, setIsSearchAndSuggestionSectionVisible] = useState(false);
  const [isPupilsPresentSectionVisible, setIsPupilsPresentSectionVisible] = useState(true);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [anyEditsMade, setAnyEditsMade] = useState(false);
  const numberOfColumns = width > 1700 ? 5 : width > 1330 ? 4 : width > 1000 ? 3 : width > 680 ? 2 : 1;
  const userDetailsFromUserContext = useContext(UserContext);
  const [secretUpdateToAbsentList, setSecretUpdateToAbsentList] = useState([]);
  const [secretCreateNewPresentList, setSecretCreateNewPresentList] = useState([]);
  const navigation = useNavigation();

  // Get the current Epoch time in milliseconds
const epochTimeInMillis = Date.now();

// Convert milliseconds to seconds
const epochTimeInSeconds = Math.floor(epochTimeInMillis / 1000);















  const NavigateToListOfPreviousSessions = () => {
    navigation.navigate("ActivitiesPreviousSessions");
  };




  const handleDeleteItem = (itemId) => {
    // // Filter out the item with the given itemId
    // const updatedAttendance = filteredAttendance.filter((item) => item.id !== itemId);

    // // Update the state with the filtered data
    // setFilteredAttendance(updatedAttendance);

    //find this user, for this date (in the route) and the oppID (also in the route) in clubattendancefromcontext
    //get the id of this record
    //run a mutation on this clubattendance query to change the attendanceMakr to A

    // Find the item from filteredAttendance using itemId
    //  const itemToDelete = filteredAttendance.find((item) => item.id === itemId);

    // If the item is found, proceed with deleting it from the server database
    if (itemId) {
      // console.log("==============looking for:", targetDate, "user:(itemID)", itemId, "opp", opportunityID);

      // Find the corresponding item in clubAttendanceFromContext based on userID, recordedDate, and opportunityID
      const itemToDeleteFromServer = clubAttendanceFromContext.find(
        (item) =>
          item.userID === itemId &&
          item.recordedDate === targetDate && // Assuming targetDate is available in the component
          item.opportunityID === opportunityID // Assuming opportunityID is available in the component's route params
      );

      // If the item is found in clubAttendanceFromContext, delete it from the server database
      if (itemToDeleteFromServer) {
        // Perform deletion logic here (e.g., making an API call to delete the record)

        ///ACTUALLY, WE'RE NOT DELETING, WE'RE SETTING ATTENDANCE MARK TO A INSTEAD OF P

        // console.log("Deleting record from server:", itemToDeleteFromServer);

        let newDeleteItem = {id: itemToDeleteFromServer.id,
       _version: itemToDeleteFromServer._version}

        setSecretUpdateToAbsentList([...secretUpdateToAbsentList, newDeleteItem]);

        // After successfully deleting from server, you can also update the UI state (filteredAttendance) if needed
        // For example, remove the item from filteredAttendance state
        const updatedAttendance = filteredAttendance.filter((item) => item.id !== itemId);
        setFilteredAttendance(updatedAttendance);

        setAnyEditsMade(true);
      } else {
        console.log("Item not found in clubAttendanceFromContext:", itemToDelete);
      }
    } else {
      console.log("Item not found in filteredAttendance:", itemId);
    }
  };

  // console.log('+++++++++++++++++++++++++++++++++++++clubAttendanceFromContext',clubAttendanceFromContext)

  const handleAddItem = (itemId) => {
    // Check if the item with itemId is already in filteredAttendance
    const isItemExist = filteredAttendance.some((item) => item.id === itemId);

    // If the item doesn't exist in filteredAttendance, add it
    if (!isItemExist) {
      // Find the item from allUserDetailsFromAllUsersContext using itemId
      const newItem = allUserDetailsFromAllUsersContext.find((user) => user.id === itemId);
      // Log the entire record being added
      console.log("Record being added:", newItem);
      // Update the state with the new item added to filteredAttendance
      setFilteredAttendance([...filteredAttendance, newItem]);
      setSecretCreateNewPresentList([...secretCreateNewPresentList, newItem])
      setAnyEditsMade(true);
    }
  };

  const findUniqueUsers = () => {
    if (clubAttendanceFromContext) {
      // Extract unique users using a Set
      const uniqueUsersSet = new Set(clubAttendanceFromContext.map((item) => item.User.id));

      // Create an array of unique user information
      const uniqueUsersArray = Array.from(uniqueUsersSet).map((userId) => {
        const user = clubAttendanceFromContext.find((item) => item.User.id === userId).User;
        return user;
      });

      // Set the state with unique user information
      setUniqueUsers(uniqueUsersArray);
    }
  };

  useEffect(() => {
    findUniqueUsers();
  }, [clubAttendanceFromContext]);

  // Compare filteredAttendance and uniqueUsers to get suggestedUsers
  useEffect(() => {
    const suggested = uniqueUsers.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
    setSuggestedUsers(suggested);
  }, [filteredAttendance, uniqueUsers]);

  // Compare filteredAttendance and filteredData to get filteredwithoutadded
  useEffect(() => {
    const searchFilteredWithoutAdded = filteredData.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
    setFilteredWithoutAdded(searchFilteredWithoutAdded);
  }, [filteredAttendance, filteredData]);

  const getThisDatesAttendance = () => {
    // Filter the data based on the target date
    const filteredAttendance = clubAttendanceFromContext.filter((item) => item.recordedDate === targetDate);

    // Extract user information for the filtered attendance
    const filteredAttendanceWithUsers = filteredAttendance.map((item) => item.User);

    // Set the state with the filtered data
    setFilteredAttendance(filteredAttendanceWithUsers);
  };

  useEffect(() => {
    if (clubAttendanceFromContext) {
      getThisDatesAttendance();
    }
  }, [clubAttendanceFromContext]);

  useEffect(() => {
    if (masterData) {
      setFilteredData(masterData);
    }
  }, [masterData]);

  const SaveRegister = async () => {

    // console.log("filteredAttendance", filteredAttendance);
    // console.log("secretUpdateToAbsentList", secretUpdateToAbsentList);
    // console.log('secretCreateNewPresentList', secretCreateNewPresentList)

    if (secretUpdateToAbsentList.length > 0) {

      // console.log('secretUpdateToAbsentList',secretUpdateToAbsentList)
      // console.log('number of items in secretUpdateToAbsnet list is ', secretUpdateToAbsentList.length)

      try {
        let deleteCount = 0;

        for (const item of secretUpdateToAbsentList) {
          

          const deleteInput = {
            input: {
              id: item.id,
              _version: item._version,
              attendanceMark: "A",
            },
          };

          const deleteResult = await API.graphql(graphqlOperation(updateClubAttendance, deleteInput));
          // console.log("this is the delete for record", deleteInput);
          // console.log('update results', deleteResult)
          deleteCount = deleteCount + 1;
        }
      } catch (error) {
        console.error("Error updating register:", error);
      }
    }


    if(secretCreateNewPresentList.length>0){

      try{

        let createCount = 0;

        for (const item of secretCreateNewPresentList){

          createCount = createCount+1

             // Prepare input for GraphQL mutation
        const createInput = {
          input: {
            attendanceMark: "P",
            recordedDate: targetDate, // Date recorded should be current date/////////////////////////////////////////////////////////////////////////////////////////////////////////
            userID: item.id, // User ID from filteredAttendance item
            opportunityID: opportunityID, // Opportunity ID from route.params
            recordedByID: userDetailsFromUserContext.id, //from context
          },
        };

// Perform GraphQL mutation
const result = await API.graphql(graphqlOperation(createClubAttendance, createInput));

// Log the result (you can handle this as per your requirement)
console.log("Mutation result:", result);



 // Generate the alert message
 let alertMessage = `Changes have been saved`;

 if (Platform.OS === "web") {
   alert(alertMessage);
 } else {
   Alert.alert(alertMessage);
 }
 // Reset anyEditsMade state if needed
 setAnyEditsMade(false);



        }
      } catch (error) {
        console.error("Error saving register:", error);
      }

    }


    // clubAttendanceForThisClubQueryAndContextUpdate({opportunityID:opportunityID, updateClubAttendanceContext})




    const fetchData = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(clubAttendanceByOpportunityID, {
            opportunityID: opportunityID,
            filter: {
              attendanceMark: { eq: "P" },
            },
          })
        );
  
        if (response) {
          updateClubAttendanceContext(response.data.clubAttendanceByOpportunityID.items);
        }
      } catch (error) {
        console.error("Error fetching data on ActivitiesPreviousSessions screen:", error);
      }
    };

    fetchData()





  
    
    NavigateToListOfPreviousSessions()


  };

  const SaveButtonListFooter = ()=>{
    return(
      <View style={{backgroundColor:'transparent',
      //  width:'100%',
       alignItems:'center'}}>
        {anyEditsMade && (
              
              
   <ActionButton icon={'check-square'} text={'Save this register'} onPressFunction={SaveRegister} />)}</View>)
  }

  return (
    <View
      style={{
        backgroundColor: Colours[colorScheme].background,
        flex: 1,
      }}>
      <View style={{ flex: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, backgroundColor: Colours[colorScheme].practicallyBackground, alignItems: "center" }}>
        {/* <ScrollView
        style={{
          
          flexGrow: 1
        }}> */}

        <View style={{ height: 50, width: "100%", backgroundColor: "transparent" }}>
          <CloseButton />
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "transparent",
            flex: 1,
            paddingHorizontal: 10,
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Pressable
            onPress={() => {
              VibrateOnce();

              setIsSearchAndSuggestionSectionVisible(!isSearchAndSuggestionSectionVisible);
            }}
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              padding: 10,
              backgroundColor: Colours[colorScheme].almostBackground,
            }}>
            <Feather name={isSearchAndSuggestionSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

            <BoldText style={{ color: Colours[colorScheme].text }}>Add more pupils</BoldText>
          </Pressable>

          {isSearchAndSuggestionSectionVisible && (
            <View
              style={{
                marginBottom: 10,
                padding: 10,
                borderBottomLeftRadius: 10,
                // height: "60%",
                borderBottomRightRadius: 10,
                width: "100%",
                backgroundColor: Colours[colorScheme].background,
              }}>
              {/* search bar */}

              <SearchBar masterData={masterData} filteredData={filteredData} setFilteredData={setFilteredData} />

              <View style={{ backgroundColor: "transparent", height: 150 }}>
                {filteredData.length < masterData.length ? (
                  <FlatList data={filteredWithoutAdded} horizontal renderItem={({ item }) => <PhotoListItemRegisterSearch item={item} onPressToAdd={handleAddItem} />} />
                ) : (
                  <>
                    <FlatList data={suggestedUsers} horizontal renderItem={({ item }) => <PhotoListItemRegisterSeearchSuggestion item={item} onPressToAdd={handleAddItem} />} />
                  </>
                )}
              </View>
            </View>
          )}

          {!isSearchAndSuggestionSectionVisible && (
            <View
              style={{
                marginBottom: 10,
                padding: 10,
                borderBottomLeftRadius: 10,
                // height: "60%",
                borderBottomRightRadius: 10,
                width: "100%",
                backgroundColor: Colours[colorScheme].background,
              }}></View>
          )}

          <Pressable
            onPress={() => {
              VibrateOnce();

              setIsPupilsPresentSectionVisible(!isPupilsPresentSectionVisible);
            }}
            style={{
              borderTopLeftRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              borderTopRightRadius: 10,
              width: "100%",
              padding: 10,
              backgroundColor: Colours[colorScheme].almostBackground,
            }}>
            <Feather name={isPupilsPresentSectionVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />

            <BoldText style={{ color: Colours[colorScheme].text }}>
              {filteredAttendance.length} {filteredAttendance.length === 1 ? "pupil" : "pupils"} present
            </BoldText>
          </Pressable>
          {isPupilsPresentSectionVisible && (
            <View
              style={{
                marginBottom: 10,
                padding: 10,
                borderBottomLeftRadius: 10,
                // height: "50%",
                flex: 1,
                borderBottomRightRadius: 10,
                width: "100%",
                // alignItems:'center',
                backgroundColor: Colours[colorScheme].background,
              }}>
              {filteredAttendance.length === 0 ? (
                <Text style={{ color: Colours[colorScheme].text, padding: 5 }}>
                  No one came to {clubAttendanceFromContext[0].Opportunity.name} on {goodLookingDate}. 🙁
                </Text>
              ) : null}
              {filteredAttendance.length > 0 ? (
                <Text style={{ color: Colours[colorScheme].text, padding: 5 }}>
                  The following {filteredAttendance.length === 1 ? null : filteredAttendance.length}
                  {filteredAttendance.length === 1 ? "pupil was" : " pupils were"} present at {clubAttendanceFromContext[0].Opportunity.name} on {goodLookingDate}:
                </Text>
              ) : null}

              <FlatList
                data={filteredAttendance}
                renderItem={({ item }) => <PhotoListItemRegister item={item} onPressDelete={handleDeleteItem} />}
                numColumns={numberOfColumns}
                style={numberOfColumns > 1 ? null : { alignSelf: "center" }}
                // columnWrapperStyle={numberOfColumns > 1 ? { justifyContent: "space-between" } : null}
                key={numberOfColumns}
                ListFooterComponent={<SaveButtonListFooter />}
              />
            </View>
          )}
          {!isPupilsPresentSectionVisible && (
            <View
              style={{
                marginBottom: 10,
                padding: 10,
                borderBottomLeftRadius: 10,
                // height: "60%",
                borderBottomRightRadius: 10,
                width: "100%",
                backgroundColor: Colours[colorScheme].background,
              }}></View>
          )}
        </View>

        {/* </ScrollView> */}
      </View>
    </View>
  );
};

export default ActivitiesPreviousRegister;

const styles = StyleSheet.create({});
