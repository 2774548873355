import { StyleSheet, TextInput, Keyboard, Pressable, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { Feather } from "@expo/vector-icons";

import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { VibrateOnce } from "../functions/vibration";

const SearchBarNew = ({ masterData, setFilteredData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const colorScheme = useColorScheme();
  const [clicked, setClicked] = useState(false);

  const handleSearch = (text) => {
    setSearchTerm(text);
    const filteredList = masterData.filter((user) => {
      const fullName = `${user.preferredFirstName} ${user.preferredSurname} ${user.firstName} ${user.surname}`.toLowerCase();
      const id = user.id.toLowerCase();
      return fullName.includes(text.toLowerCase()) || id.includes(text.toLowerCase());
    });
    setFilteredData({ filteredUsers: filteredList, searchTerm: text });
  };

  return (
    <View style={{ backgroundColor: 'transparent', width: '100%', justifyContent: "flex-start",
      alignItems: "center", }}>
      <View style={{ backgroundColor: "transparent", width: '90%', maxWidth: 400, flexDirection: "row" }}>
        <View
          style={
            !clicked
              ? [styles.searchBar__unclicked, { backgroundColor: Colours[colorScheme].almostBackground, borderColor: 'transparent' }]
              : [styles.searchBar__clicked, { backgroundColor: Colours[colorScheme].almostBackground, borderColor: Colours.palette.logoGreen }]
          }>
          <Feather name="search" size={20} color={Colours[colorScheme].text} style={{ marginLeft: 1 }} />
          <TextInput
            style={[
              Platform.OS === "web" ? { outlineWidth: 0 } : null,
              styles.input,
              { color: Colours[colorScheme].text },
            ]}
            placeholder="Search for anyone..."
            placeholderTextColor={Colours[colorScheme].textLessEmphasised}
            value={searchTerm}
            onChangeText={handleSearch}
            onFocus={() => setClicked(true)}
          />
        </View>
        {clicked && (
          <View style={{ flex: 1, backgroundColor: "transparent", alignItems: "center", justifyContent: "center" }}>
          <Pressable
            style={({ pressed }) => ({
              padding: 5,
              borderRadius: 5,
              backgroundColor: pressed ? Colours[colorScheme].almostBackground : 'transparent'
            })}
            onPress={() => {
              Keyboard.dismiss();
              VibrateOnce();
              setClicked(false);
              setSearchTerm("");
              handleSearch("");
            }}>
            <Text style={{ fontFamily: "avenir-next", fontSize: 12, color: Colours[colorScheme].text }}>
              Cancel
            </Text>
          </Pressable>
          </View>
        )}
      </View>
    </View>
  );
};

export default SearchBarNew;

const styles = StyleSheet.create({
  searchBar__unclicked: {
    padding: 10,
    flexDirection: "row",
    width: "95%",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
  },
  searchBar__clicked: {
    padding: 10,
    flexDirection: "row",
    width: "80%",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
  },
  input: {
    fontSize: 14,
    marginLeft: 10,
    width: "90%",
    fontFamily: "avenir-next",
  },
});