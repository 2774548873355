// import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
// import { FlatList, Pressable, ScrollView, StyleSheet, TextInput } from "react-native";
// import { View, Text } from "../constants/themedViewAndText";
// import useColorScheme from "../hooks/useColorScheme";
// import Colours from "../constants/colours";
// import { AllUsersContext, UserContext } from "../hooks/Contexts";
// import ActionButton from "../components/ActionButton";
// import HouseLogo from "../components/HouseLogo";
// import { useNavigation } from "@react-navigation/native";
// import { Picker } from "@react-native-picker/picker";
// import SearchBar from "../components/SearchBar";

// const AdminUsers = () => {
//   const userDetailsFromUserContext = useContext(UserContext);
//   const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
//   const [filteredUsers, setFilteredUsers] = useState(allUserDetailsFromAllUsersContext);
//   const [filteredAndSearchedUsers, setFilteredAndSearchedUsers] = useState(allUserDetailsFromAllUsersContext);
//   const colorScheme = useColorScheme();
//   const type = userDetailsFromUserContext.type;
//   const navigation = useNavigation();

//   const [nameSearch, setNameSearch] = useState("");
//   const [idSearch, setIdSearch] = useState("");
//   const [typeFilter, setTypeFilter] = useState("");
//   const [houseFilter, setHouseFilter] = useState("");
//   const [yearFilter, setYearFilter] = useState("");

//   const resetFilters = () => {
//     setNameSearch("");
//     setIdSearch("");
//     setTypeFilter("");
//     setHouseFilter("");
//     setYearFilter("");
//   };

//   const ClearAllButton = () => (
//     <Pressable style={{ backgroundColor: "transparent" }} onPress={resetFilters}>
//       <Text style={{ color: Colours[colorScheme].text }}>Clear All</Text>
//     </Pressable>
//   );

//   // const filterUsers = () => {
//   //   const filtered = allUserDetailsFromAllUsersContext.filter((user) => {
//   //     const userType = user.type;
//   //     const house = user.Enrolments?.items[0]?.groupingData1;
//   //     const year = user.Enrolments?.items[0]?.groupingData3;

//   //     return (
//   //       (typeFilter === "" || userType === typeFilter) && (houseFilter === "" || house === houseFilter) && (yearFilter === "" || year === yearFilter)
//   //     );
//   //   });

//   //   setFilteredUsers(filtered);
//   // };



//   const filterUsers = () => {
//     const filtered = allUserDetailsFromAllUsersContext.filter((user) => {
//       const userType = user.type;
//       const house = user.Enrolments?.items[0]?.groupingData1;
//       const year = user.Enrolments?.items[0]?.groupingData3;
  
//       return (
//         (typeFilter === "" || userType === typeFilter) &&
//         (houseFilter === "" || house === houseFilter) &&
//         (yearFilter === "" || year === yearFilter)
//       );
//     });
  
//     setFilteredAndSearchedUsers(filtered);
//   };








//   useEffect(() => {
//     filterUsers();
//   }, [

//     typeFilter,
//     houseFilter,
//     yearFilter,
//   ]);

//   const debounce = (func, delay) => {
//     let timeoutId;
//     return (...args) => {
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => {
//         func.apply(null, args);
//       }, delay);
//     };
//   };

//   const FilterDropdown = ({ label, value, onValueChange, items }) => (
//     // <View style={styles.filterContainer}>

//     <Picker
//       style={{
//         outlineStyle: "none",
//         color: Colours[colorScheme].text,
//         backgroundColor: Colours[colorScheme].almostBackground,
//         // borderColor: Colours[colorScheme].edging,

//         borderWidth: 0,
//         margin: 10,
//         padding: 10,
//         borderRadius: 8,
//         // fontSize: 14,
//         fontFamily: "avenir-next",
//       }}
//       selectedValue={value}
//       onValueChange={onValueChange}>
//       <Picker.Item label={label} value="" />
//       {items.map((item) => (
//         <Picker.Item key={item} label={item} value={item} />
//       ))}
//     </Picker>
//     // </View>
//   );

//   const RenderItem = ({ item }) => {
//     // const shouldRenderItem =
//     //   (typeFilter === "" || item.type === typeFilter) &&
//     //   (houseFilter === "" || item.Enrolments?.items[0]?.groupingData1 === houseFilter) &&
//     //   (yearFilter === "" || item.Enrolments?.items[0]?.groupingData3 === yearFilter);

//     // if (!shouldRenderItem) {
//     //   return null;
//     // }

//     let house = item.Enrolments?.items[0]?.groupingData1;
//     let coach = item.Enrolments?.items[0]?.groupingData2;
//     let year = item.Enrolments?.items[0]?.groupingData3;
//     const NavigateToUserDetails = () => {
//       navigation.navigate("AdminUserDetails", { userID: item.id });
//     };

//     return (
//       <Pressable
//         onPress={NavigateToUserDetails}
//         style={{
//           backgroundColor: Colours[colorScheme].background,
//           borderBottomColor: Colours[colorScheme].edging,
//           flexDirection: "row",
//           borderBottomWidth: 1,
//           alignItems: "center",
//           alignSelf: "center",
//           width: "90%",
//         }}>
//         <View
//           style={{
//             padding: 10,
//             width: "10%",
//             alignItems: "center",
//             backgroundColor: "transparent",
//           }}>
//           <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
//             {item.type}
//           </Text>
//         </View>
//         <View
//           style={{
//             padding: 10,
//             width: "40%",
//             alignItems: "center",
//             backgroundColor: "transparent",
//           }}>
//           <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
//             {item.preferredFirstName} {item.preferredSurname}
//           </Text>
//         </View>

//         <View
//           style={{
//             padding: 10,
//             width: "25%",
//             alignItems: "center",
// justifyContent:'center',
//             backgroundColor: "transparent",
//             flexDirection: "row",
//           }}>
//           {house ? (
//             <View style={{ height: 20, width: 20 }}>
//               <HouseLogo house={house} />
//             </View>
//           ) : null}
//         </View>
//         <View
//           style={{
//             padding: 10,
//             width: "25%",
//             alignItems: "center",
//             justifyContent:'center',
//             backgroundColor: "transparent",
//             flexDirection: "row",
//           }}>
//           {year ? (
//             <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
//               {year}
//             </Text>
//           ) : null}
//         </View>
//       </Pressable>
//     );
//   };

//   return (
//     <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, flex: 1 }}>
//       <View style={{ backgroundColor: "transparent", justifyContent: "center", flexDirection: "row", padding: 10 }}>
//         <Text>Displaying {filteredAndSearchedUsers.length} users</Text>
//         {/* <ClearAllButton /> */}
//       </View>
//       <View style={{ backgroundColor:'transparent', alignItems: "center", alignSelf: "center", width: "90%", flexDirection: "row" }}>
//         {/* TYPE DROP DOWN */}
//         <View
//           style={{
//             padding: 10,
//             width: "10%",
//             alignItems: "center",
//             backgroundColor: "transparent",
//           }}>
//           <FilterDropdown label="Type" value={typeFilter} onValueChange={setTypeFilter} items={["CHILD", "STAFF", "ADMIN", "ALUMNI"]} />
//         </View>

//         {/* NAME SEARCH BOX */}
//         <View
//           style={{
//             padding: 10,
//             width: "40%",
//             alignItems: "center",
//             backgroundColor: "transparent",
//           }}>
//           {/* <SearchBar masterData={allUserDetailsFromAllUsersContext} filteredData={filteredAndSearchedUsers} setFilteredData={setFilteredAndSearchedUsers} /> */}


//           <SearchBar
//   masterData={filteredAndSearchedUsers}
//   filteredData={filteredAndSearchedUsers}
//   setFilteredData={setFilteredAndSearchedUsers}
// />


//         </View>

//         {/*HOUSE DROP DOWN */}
//         <View
//           style={{
//             padding: 10,
//             width: "25%",
//             alignItems: "center",
//             justifyContent:'center',
//             backgroundColor: "transparent",
//             flexDirection: "row",
//           }}>
//           <FilterDropdown label="House" value={houseFilter} onValueChange={setHouseFilter} items={["Bremner", "Robinson", "Tomlinson", "Trueman"]} />
//         </View>

//         {/* YEAR DROP DOWN */}
//         <View
//           style={{
//             padding: 10,
//             width: "25%",
//             alignItems: "center",
//             justifyContent:'center',
//             backgroundColor: "transparent",
//             flexDirection: "row",
//           }}>
//           <FilterDropdown
//             label="Year"
//             value={yearFilter}
//             onValueChange={setYearFilter}
//             items={["Year R", "Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10", "Year 11"]} // Replace with your actual years
//           />
//         </View>
//       </View>
//       <View style={{ flex: 1, width: "100%", backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
//         <FlatList style={{ width: "100%" }} data={filteredAndSearchedUsers} renderItem={({ item }) => <RenderItem item={item} />} keyExtractor={(item) => item.id} />
//       </View>
//     </View>
//   );
// };

// export default AdminUsers;

// const styles = StyleSheet.create({
// });









import React, { useCallback, useContext, useEffect, useState } from "react";
import { FlatList, Pressable } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import HouseLogo from "../components/HouseLogo";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import SearchBar from "../components/SearchBarNew";
import SearchBarNew from "../components/SearchBarNew";

type User = {
  id: string;
  type: string;
  preferredFirstName: string;
  preferredSurname: string;
  firstName: string;
  surname: string;
  Enrolments?: {
    items: Array<{
      groupingData1?: string;
      groupingData3?: string;
    }>;
  };
};

const AdminUsers = () => {
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext) as User[];
  const [filteredAndSearchedUsers, setFilteredAndSearchedUsers] = useState<User[]>(allUserDetailsFromAllUsersContext);
  const colorScheme = useColorScheme();
  const navigation = useNavigation();

  const [typeFilter, setTypeFilter] = useState("");
  const [houseFilter, setHouseFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const resetFilters = () => {
    setTypeFilter("");
    setHouseFilter("");
    setYearFilter("");
    setSearchTerm("");
  };

  const applyFiltersAndSearch = useCallback(() => {
    let filtered = allUserDetailsFromAllUsersContext;
    
    if (searchTerm) {
      filtered = filtered.filter((user) => {
        const fullName = `${user.preferredFirstName} ${user.preferredSurname} ${user.firstName} ${user.surname}`.toLowerCase();
        const id = user.id.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase()) || id.includes(searchTerm.toLowerCase());
      });
    }

    filtered = filtered.filter((user) => {
      const userType = user.type || "";
      const house = user.Enrolments?.items[0]?.groupingData1 || "";
      const year = user.Enrolments?.items[0]?.groupingData3 || "";

      const matchesType = typeFilter === "" || userType === typeFilter;
      const matchesHouse = houseFilter === "" || house === houseFilter;
      const matchesYear = yearFilter === "" || year === yearFilter;

      return matchesType && matchesHouse && matchesYear;
    });

    setFilteredAndSearchedUsers(filtered);
  }, [allUserDetailsFromAllUsersContext, typeFilter, houseFilter, yearFilter, searchTerm]);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [applyFiltersAndSearch]);

  const FilterDropdown = ({ label, value, onValueChange, items }: { label: string; value: string; onValueChange: (value: string) => void; items: string[] }) => (
    <Picker
      style={{
        outlineStyle: "none",
        color: Colours[colorScheme].text,
        backgroundColor: Colours[colorScheme].almostBackground,
        borderWidth: 0,
        margin: 10,
        padding: 10,
        borderRadius: 8,
        fontFamily: "avenir-next",
      }}
      selectedValue={value}
      onValueChange={onValueChange}>
      <Picker.Item label={label} value="" />
      {items.map((item) => (
        <Picker.Item key={item} label={item} value={item} />
      ))}
    </Picker>
  );

  const RenderItem = ({ item }: { item: User }) => {
    const house = item.Enrolments?.items[0]?.groupingData1;
    const year = item.Enrolments?.items[0]?.groupingData3;

    const NavigateToUserDetails = () => {
      navigation.navigate("AdminUserDetails" as never, { userID: item.id } as never);
    };

    return (
      <Pressable
        onPress={NavigateToUserDetails}
        style={{
          backgroundColor: Colours[colorScheme].background,
          borderBottomColor: Colours[colorScheme].edging,
          flexDirection: "row",
          borderBottomWidth: 1,
          alignItems: "center",
          alignSelf: "center",
          width: "90%",
        }}>
        <View style={{ padding: 10, width: "10%", alignItems: "center", backgroundColor: "transparent" }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>{item.type}</Text>
        </View>
        <View style={{ padding: 10, width: "40%", alignItems: "center", backgroundColor: "transparent" }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            {item.preferredFirstName} {item.preferredSurname}
          </Text>
        </View>
        <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: 'center', backgroundColor: "transparent", flexDirection: "row" }}>
          {house && <View style={{ height: 20, width: 20 }}><HouseLogo house={house} /></View>}
        </View>
        <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: 'center', backgroundColor: "transparent", flexDirection: "row" }}>
          {year && <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>{year}</Text>}
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ backgroundColor: Colours[colorScheme].practicallyBackground, flex: 1 }}>
      <View style={{ backgroundColor: "transparent", justifyContent: "center", flexDirection: "row", padding: 10 }}>
        <Text>Displaying {filteredAndSearchedUsers.length} users</Text>
      </View>
      <View style={{ backgroundColor: 'transparent', alignItems: "center", alignSelf: "center", width: "88%",//scrollbar adjustment... not accurate!
         flexDirection: "row" }}>

        <View style={{ padding: 10, width: "10%", alignItems: "center", backgroundColor: "transparent" }}>
          <FilterDropdown label="Type" value={typeFilter} onValueChange={setTypeFilter} items={["CHILD", "STAFF", "ADMIN", "ALUMNI"]} />
        </View>
        <View style={{ padding: 10, width: "40%", alignItems: "center", backgroundColor: "transparent" }}>
          <SearchBarNew
            masterData={allUserDetailsFromAllUsersContext}
            setFilteredData={({ searchTerm }) => {
              setSearchTerm(searchTerm);
            }}
          />
        </View>
        <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: 'center', backgroundColor: "transparent", flexDirection: "row" }}>
          <FilterDropdown label="House" value={houseFilter} onValueChange={setHouseFilter} items={["Bremner", "Robinson", "Tomlinson", "Trueman"]} />
        </View>
        <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: 'center', backgroundColor: "transparent", flexDirection: "row" }}>
          <FilterDropdown
            label="Year"
            value={yearFilter}
            onValueChange={setYearFilter}
            items={["Year R", "Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10", "Year 11"]}
          />
        </View>





      </View>
      <View style={{ flex: 1, width: "100%", backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
        <FlatList
          style={{ width: "100%" }}
          data={filteredAndSearchedUsers}
          renderItem={({ item }) => <RenderItem item={item} />}
          keyExtractor={(item) => item.id}
        />
      </View>
    </View>
  );
};

export default AdminUsers;











